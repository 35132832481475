import React, { useContext } from "react";
import "./styles.scss";
import { CarTypeSelection } from "../CarTypeSelection";
import { CancellationPolicySelection } from "../CancellationPolicySelection";
import { CarCompanySelection } from "../CarCompanySelection";
import { SpecificationSelection } from "../SpecificationSelection";
import {
  CarFilterTypeV5,
  ICancellationPolicy,
  ICarCompaniesFilter,
  ISpecifications,
  PassengerFilter,
} from "../../../../reducer";
import { IPriceRange, PickUpType } from "redmond";
import {
  BAGS_TEXT,
  CAR_TYPE_TEXT,
  IN_POLICY_TEXT,
  PASSENGERS,
  PICKUP_TYPE_TEXT,
  PRICE_FILTER_SUBTITLE,
  PRICE_FILTER_TITLE,
  RENTAL_COMPANY_FILTER_TITLE,
} from "../../textConstants";
import { Icon, IconName, PolicyStatusSelection } from "halifax";
import { Typography } from "@material-ui/core";
import {
  CANCELLATION_HEADING_TEXT,
  MODAL_HEADING_TEXT,
  SPECIFICATIONS_HEADING_TEXT,
} from "./textConstants";
import clsx from "clsx";
import { MaxPriceSelection } from "../MaxPriceSelection";
import { PassengersSelection } from "../PassengersSelection";
import { PickUpSelection } from "../PickUpSelection";
import { BagsSelection } from "../BagsSelection";
import { isCorpTenant, useShowPolicyBanner } from "@capone/common";
import { config } from "../../../../../../api/config";
import { ClientContext } from "../../../../../../App";

interface FilterModalContentProps {
  carTypes: CarFilterTypeV5[];
  setCarTypes: (carTypes: CarFilterTypeV5[]) => void;
  minMaxPriceRange: IPriceRange;
  maxTotalPriceFilter: number;
  setMaxTotalPriceFilter: (newMaxTotalPrice: number) => void;
  currencySymbol: string;
  cancellationPolicy: ICancellationPolicy;
  setCancellationPolicy: (cancellationPolicy: ICancellationPolicy) => void;
  allCarCompanies: { key: string; name: string }[];
  carCompanies: ICarCompaniesFilter;
  setCarCompanies: (carCompanies: ICarCompaniesFilter) => void;
  specifications: ISpecifications;
  setSpecifications: (specifications: ISpecifications) => void;
  isMobile?: boolean;
  passengers: PassengerFilter[];
  setPassengers: (passengers: PassengerFilter[]) => void;
  pickUps: PickUpType[];
  setPickUp: (pickUps: PickUpType[]) => void;
  bags: number[];
  setBags: (bags: number[]) => void;
  isInPolicy: boolean;
  setIsInPolicy: (isInPolicy: boolean) => void;
}

export const FilterModalContent = ({
  carTypes,
  minMaxPriceRange,
  maxTotalPriceFilter,
  setMaxTotalPriceFilter,
  cancellationPolicy,
  allCarCompanies,
  carCompanies,
  specifications,
  setCarTypes,
  setCancellationPolicy,
  setCarCompanies,
  setSpecifications,
  isMobile,
  passengers,
  setPassengers,
  pickUps,
  setPickUp,
  bags,
  setBags,
  isInPolicy,
  setIsInPolicy,
}: FilterModalContentProps) => {
  const { policies, sessionInfo } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies, sessionInfo);

  return (
    <div
      className={clsx("cars-filters-modal-content-container", {
        mobile: isMobile,
      })}
    >
      {!isMobile && (
        <>
          <Typography
            variant="h4"
            className="cars-filters-modal-content-heading"
          >
            {MODAL_HEADING_TEXT}
          </Typography>

          <hr />
        </>
      )}

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.CarFunnelIcon} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {CAR_TYPE_TEXT}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <CarTypeSelection
          carTypes={carTypes}
          setCarTypes={(types) => setCarTypes(types as CarFilterTypeV5[])}
          isCarsCXV1Experiment
        />
      </div>
      <hr />

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.CarFunnelIcon} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {RENTAL_COMPANY_FILTER_TITLE}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <CarCompanySelection
          options={allCarCompanies}
          carCompanies={carCompanies}
          setCarCompanies={setCarCompanies}
          isCarsCXV1Experiment
        />
      </div>
      <hr />

      {isCorpTenant(config.TENANT) && showPolicyBanner && (
        <>
          <div className="cars-filter-modal-heading">
            <Icon name={IconName.CarFunnelIcon} />
            <Typography variant="h4" className="cars-filter-modal-heading-text">
              {IN_POLICY_TEXT}
            </Typography>
          </div>
          <div className="filter-wrapper">
            <PolicyStatusSelection
              isInPolicy={isInPolicy}
              setIsInPolicy={setIsInPolicy}
            />
          </div>
          <hr />
        </>
      )}

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.MoneyOutlineThin} />
        <div>
          <Typography variant="h4" className="cars-filter-modal-heading-text">
            {PRICE_FILTER_TITLE}
          </Typography>
          <Typography className="cars-filter-modal-subtitle-text">
            {PRICE_FILTER_SUBTITLE}
          </Typography>
        </div>
      </div>
      <div className="filter-wrapper">
        <MaxPriceSelection
          minMaxPriceRange={minMaxPriceRange}
          maxPrice={maxTotalPriceFilter}
          setMaxPrice={setMaxTotalPriceFilter}
        />
      </div>
      <hr />

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.CarFunnelIcon} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {CANCELLATION_HEADING_TEXT}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <CancellationPolicySelection
          cancellation={cancellationPolicy}
          setCancellationPolicy={setCancellationPolicy}
          isCarsCXV1Experiment
        />
      </div>
      <hr />

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.GaugeOutline} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {SPECIFICATIONS_HEADING_TEXT}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <SpecificationSelection
          specifications={specifications}
          setSpecifications={setSpecifications}
          isCarsCXV1Experiment
        />
      </div>
      <hr />

      <div className="cars-filter-modal-heading">
        <Icon name={IconName.UserOutline} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {PASSENGERS}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <PassengersSelection
          passengersSelected={passengers}
          setPassengersFilter={setPassengers}
          isCarsCXV1Experiment
        />
      </div>
      <hr />
      <div className="cars-filter-modal-heading">
        <Icon name={IconName.DiagonalGreyAirplane} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {PICKUP_TYPE_TEXT}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <PickUpSelection
          isCarsCXV1Experiment
          pickUpSelected={pickUps}
          setPickUpFilter={setPickUp}
        />
      </div>
      <hr />
      <div className="cars-filter-modal-heading">
        <Icon name={IconName.SuitcaseIcon} />
        <Typography variant="h4" className="cars-filter-modal-heading-text">
          {BAGS_TEXT}
        </Typography>
      </div>
      <div className="filter-wrapper">
        <BagsSelection
          isCarsCXV1Experiment
          bagsSelected={bags}
          setBagsFilter={setBags}
        />
      </div>
    </div>
  );
};
