import React, { useContext, useMemo } from "react";
import { Box } from "@material-ui/core";
import { BookingSuccessModalConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { useDeviceTypes, CarConfirmation } from "halifax";
import { PATH_HOME, PATH_TRAVEL_SALE } from "../../../../utils/paths";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import "./styles.scss";
import {
  AVAILABLE,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_SALE,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
  TRAVEL_SALE_ACTIVE,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { useShowPolicyBanner } from "@capone/common";
import { ClientContext } from "../../../../App";
import { useExperimentsById } from "@capone/experiments";
import { CorpSessionInfo, ModalNames } from "redmond";
import { updateUserSeenModal } from "b2b-base/src/api/v1/user/updateUserSeenModal";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";

export interface IBookingSuccessModalProps
  extends BookingSuccessModalConnectorProps,
    RouteComponentProps {}

export const BookingSuccessModal = (props: IBookingSuccessModalProps) => {
  const {
    history,
    selectedVehicle,
    confirmation,
    resetBookState,
    earnString,
    email,
    userPassengers,
    selectedPassengerId,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const { policies, sessionInfo } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies, sessionInfo);

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];
  const isAdmin = userRoles.includes("Admin");

  const isNfuProductAwarenessEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "available";

  const isNfuProductAwarenessDebugEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "debug";

  const hasSeenNfuInfoModal =
    (sessionInfo as CorpSessionInfo).corporateInfo?.hasSeenModalMap?.[
      ModalNames.NFU_IN_PRODUCT_AWARENESS
    ] ?? false;

  const shouldShowNfuProductAwareness =
    (isAdmin && !hasSeenNfuInfoModal && isNfuProductAwarenessEnabled) ||
    isNfuProductAwarenessDebugEnabled;

  const getUserName = () => {
    const selectedPassenger = userPassengers.find(
      (user) => user.id === selectedPassengerId
    );
    return selectedPassenger
      ? `${selectedPassenger?.givenName} ${selectedPassenger?.surname}`
      : "your users";
  };

  // TODO: since 'vehicle' is not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve this state).
  if (!selectedVehicle || !confirmation) {
    history.push(PATH_HOME);
    return null;
  }

  const treesModalText = {
    treesModalHeader: textConstants.TREES_MODAL_HEADER,
    treesModalTitle: textConstants.TREES_MODAL_TITLE,
    treesModalSubtitle: textConstants.TREES_MODAL_SUBTITLE,
    treesModalImgLocation: textConstants.TREES_MODAL_IMG_LOCATION,
    treesModalLinkCopy: textConstants.TREES_MODAL_CTA_TEXT,
    treesModalBoldedLinkCopy: textConstants.TREES_BOLDED_MODAL_CTA_TEXT,
  };

  return (
    <Box
      className={clsx("car-booking-success-container", {
        mobile: matchesMobile,
      })}
    >
      <CarConfirmation
        bannerCopy={textConstants.BANNER_COPY}
        title={textConstants.TITLE(email || "")}
        subtitle={textConstants.SUBTITLE(
          confirmation.confirmationInfo?.supplierBookingId ||
            confirmation.confirmationInfo?.providerBookingId ||
            confirmation.groundBookingId
        )}
        nextHeader={textConstants.WHATS_NEXT_HEADER}
        infoCardTitles={{
          flightsTitle: textConstants.FLIGHTS_TITLE,
          flightsDescription: textConstants.FLIGHTS_DESCRIPTION,
          hotelsTitle: textConstants.HOTELS_TITLE,
          hotelsDescription: textConstants.HOTELS_DESCRIPTION,
        }}
        onFlightsClick={() => {
          history.push("/flights");
          resetBookState();
        }}
        onHotelsClick={() => {
          history.push("/hotels");
          resetBookState();
        }}
        isMobile={matchesMobile}
        vehicle={selectedVehicle}
        pickUp={confirmation.pickUp}
        dropOff={confirmation.dropOff}
        earnString={earnString}
        hidePriceAndReward={matchesMobile && true}
        displayTrees={isTreesModalExperiment}
        treesModalText={treesModalText}
        travelSalesBannerProps={
          travelSalesEventVariant === TRAVEL_SALE_ACTIVE
            ? {
                subtitle: textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
                ctaText: textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA,
                ctaOnClick: () => {
                  const path = `${PATH_TRAVEL_SALE}?entryType=cars_confirmation`;
                  matchesMobile
                    ? history.push(path)
                    : window.open(path, "_blank");
                },
              }
            : undefined
        }
        isProdEnv={window.__mclean_env__.ENV === "production"}
        tenant={config.TENANT}
        showPolicyBanner={showPolicyBanner}
        shouldShowNfuProductAwareness={shouldShowNfuProductAwareness}
        travelerName={getUserName()}
        handleSeenNfuInfoModal={updateUserSeenModal}
        trackEvent={trackEvent}
      />
    </Box>
  );
};
