import {
  VehicleAvailability,
  ImageKind,
  BagSize,
  VehicleKindEnum,
  CarsCancellationPolicyEnum,
  ResultsContext,
  PickUpType,
  VehicleKindEnumV5,
  CorporateTravel,
  CorpVehicleAvailability,
} from "redmond";
import {
  ICarDetailsCardContent,
  getTotalPriceText,
  getRewardText,
} from "halifax";
import dayjs from "dayjs";

export interface ICarAvailabilityLineItem
  extends ICarDetailsCardContent,
    VehicleAvailability {
  index?: number;
  id?: string;
  opaqueShopRequestVehicle: string;
  opaqueShopRequestContext: string;
}

export interface ICorpCarAvailabilityLineItem extends ICarAvailabilityLineItem {
  corporateTravel: CorporateTravel;
}

const getCarImage = (
  provider: string,
  originalImage?: string,
  largeImage?: string,
  illustrationImage?: string,
  smallImage?: string
): string => {
  switch (provider) {
    case "PL":
      return (
        largeImage ?? originalImage ?? illustrationImage ?? smallImage ?? ""
      );
    default:
      return (
        originalImage ?? largeImage ?? illustrationImage ?? smallImage ?? ""
      );
  }
};

export const getCarAvailabilityLineItem = (
  vehicle: VehicleAvailability | CorpVehicleAvailability,
  context: ResultsContext,
  rewardsKey: string | null,
  locationLabel: string,
  index?: number
): ICarAvailabilityLineItem | ICarAvailabilityLineItem => {
  const diffInDays = dayjs(vehicle.dropOff.time).diff(
    dayjs(vehicle.pickUp.time),
    "days"
  );
  const passengers = vehicle.vehicle.passengerCapacity;
  const bags = vehicle.vehicle.bagCapacity[BagSize.Unspecified];
  const pickUpType = vehicle.pickUp.pickUpInformation?.pickUpType;
  const metersToRequested = vehicle.pickUp.metersToRequested;
  const totalChargeInFiat = vehicle.totalCharge.fiat;
  const totalChargeInRewards = rewardsKey
    ? vehicle.totalCharge.rewards[rewardsKey]
    : null;

  const images = vehicle.vehicle.images;
  const smallImage = images ? images[ImageKind.Small] : undefined;
  const originalImage = images ? images[ImageKind.Original] : undefined;
  const largeImage = images ? images[ImageKind.Large] : undefined;
  const illustrationImage = images ? images[ImageKind.Illustration] : undefined;
  return {
    index,
    id: vehicle.vehicle.id,
    opaqueShopRequestVehicle: vehicle.opaqueShopRequest,
    opaqueShopRequestContext: context.opaqueShopRequestContext,
    days: `${diffInDays} ${diffInDays > 1 ? "days" : "day"}`,
    totalFiatPrice: getTotalPriceText({ price: totalChargeInFiat }),
    supplierName: context.suppliers[vehicle.supplierRef]?.name ?? "",
    totalRewards: totalChargeInRewards
      ? getRewardText({ reward: totalChargeInRewards, round: true })
      : "",
    carImgAssets: {
      supplierIconUrl: context.suppliers[vehicle.supplierRef]?.logo ?? "",
      // note: some data that we get are really missing images
      originalCarImgUrl: getCarImage(
        vehicle.vendorRef,
        originalImage,
        largeImage,
        illustrationImage,
        smallImage
      ),
      illustrationCarImgUrl:
        illustrationImage ?? largeImage ?? originalImage ?? smallImage ?? "",
    },
    carModelDetails: {
      carSize: getCarSizeText(vehicle.vehicle.kind),
      // TODO: ask about where we get multi-car entries
      carModels: [vehicle.vehicle.name],
    },
    carInfoIconDetails: {
      passengerCapacity: `${Math.max(passengers ?? 0, 0)} ${passengers === 1 ? "Passenger" : "Passengers"}`,
      bagCapacity: `${Math.max(bags ?? 0, 0)} ${bags === 1 ? "Bag" : "Bags"}`,
      transmissionType: vehicle.vehicle.transmissionType,
      airConditioning: vehicle.vehicle.airConditioning
        ? "Air Conditioning"
        : undefined,
      metersToRequested:
        pickUpType == PickUpType.Airport
          ? `<span class="pick-up-text">Pick-up at airport</span>`
          : pickUpType == PickUpType.Shuttle
          ? `<span class="pick-up-text">Free airport shuttle to pick-up location</span>`
          : metersToRequested
          ? `<span class="pick-up-text">${(
              metersToRequested / MILE_TO_METERS
            ).toFixed(
              1
            )} miles</span> <span class="pick-up-location-text">from ${locationLabel}</span>`
          : undefined,
      showAirplaneIcon:
        pickUpType == PickUpType.Airport || pickUpType == PickUpType.Shuttle,
    },
    carAvailabilityTagDetails: {
      freeCancellation:
        vehicle.cancellationPolicy.CancellationPolicy ===
        CarsCancellationPolicyEnum.FreeCancellation,
      hasUnlimitedMileage: vehicle.hasUnlimitedMileage,
      CancellationPolicy: vehicle.cancellationPolicy.CancellationPolicy,
      cancellationDatePassed:
        vehicle.cancellationPolicy.hoursBeforePickUp !== undefined &&
        dayjs(vehicle.pickUp.time)
          .subtract(vehicle.cancellationPolicy.hoursBeforePickUp, "h")
          .isBefore(dayjs()),
      freeCancellationUntil: vehicle.cancellationPolicy.hoursBeforePickUp
        ? dayjs(vehicle.pickUp.time)
            .subtract(vehicle.cancellationPolicy.hoursBeforePickUp ?? 0, "h")
            .format("MMM DD")
        : undefined,
    },
    ...("corporateTravel" in vehicle && {
      corporateTravel: vehicle.corporateTravel,
    }),
    ...vehicle,
  };
};

const pascalCaseToWords = (pascalCaseString: string) =>
  pascalCaseString.replace(/([A-Z1-9])/g, " $1").trim();

const getCarSizeFromKindEnum = (kind: VehicleKindEnumV5) =>
  pascalCaseToWords(kind)
    .replace("S U V", "SUV")
    .replace("Mid Size", "Midsize")
    .replace("Full Size", "Full-size")
    .replace("Van Truck", "Van/Truck")
    .replace("All Terrain", "All-Terrain");

export const getCarSizeText = (kind: VehicleKindEnum | VehicleKindEnumV5) => {
  switch (kind) {
    case VehicleKindEnum.Truck:
      return "Truck";
    case VehicleKindEnum.Small:
      return "Small Car";
    case VehicleKindEnum.Mystery:
      return "Determined at pick-up";
    case VehicleKindEnum.SUV:
      return "SUV";
    case VehicleKindEnum.Mini:
      return "Mini Car";
    case VehicleKindEnum.Intermediate:
      return "Intermediate Car";
    case VehicleKindEnum.Van:
      return "Van";
    case VehicleKindEnum.Luxury:
      return "Luxury Car";
    case VehicleKindEnum.FullSize:
      return "Full-Size Car";
    case VehicleKindEnum.Compact:
      return "Compact Car";
    case VehicleKindEnum.Economy:
      return "Economy Car";
    case VehicleKindEnum.Standard:
      return "Standard Car";
    case VehicleKindEnum.SuppliersChoice:
      return "Supplier's Choice";
    default:
      return getCarSizeFromKindEnum(kind);
  }
};

const MILE_TO_METERS = 1609.344;

export const getVehiclePricePerDay = (vehicle: VehicleAvailability) => {
  const diffInDays = dayjs(vehicle.dropOff.time).diff(
    dayjs(vehicle.pickUp.time),
    "days"
  );

  return vehicle.payNow.fiat.value / Math.max(diffInDays, 1);
};

export enum CarFilterTypeV5 {
  Compact = "Compact",
  Truck = "Truck",
  Luxury = "Luxury",
  Convertible = "Convertible",
  Electric = "Electric",
  SUV = "SUV",
  Van = "Van",
  Hybrid = "Hybrid",
  Economy = "Economy",
  FullSize = "FullSize",
  MidSize = "MidSize",
  Mini = "Mini",
  Premium = "Premium",
  Standard = "Standard",
}

export const vehicleKindToCarFilterTypeV5: {
  [vehicleKind: string]: CarFilterTypeV5[];
} = {
  [VehicleKindEnumV5.CompactCar]: [CarFilterTypeV5.Compact],
  [VehicleKindEnumV5.CompactCommercialVanTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.CompactConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.CompactCrossover]: [CarFilterTypeV5.Compact],
  [VehicleKindEnumV5.CompactElectricSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.CompactElectricVan]: [
    CarFilterTypeV5.Van,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.CompactEliteCar]: [CarFilterTypeV5.Compact],
  [VehicleKindEnumV5.CompactEliteCoupe]: [CarFilterTypeV5.Compact],
  [VehicleKindEnumV5.CompactEliteCrossover]: [CarFilterTypeV5.Compact],
  [VehicleKindEnumV5.CompactEliteElectricCrossover]: [CarFilterTypeV5.Electric],
  [VehicleKindEnumV5.CompactEliteSportsCar]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Compact,
  ],
  [VehicleKindEnumV5.CompactEliteSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.CompactHybridCar]: [CarFilterTypeV5.Hybrid],
  [VehicleKindEnumV5.CompactRecreationalVehicle]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.CompactSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.CompactVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.EconomyCar]: [CarFilterTypeV5.Economy],
  [VehicleKindEnumV5.EconomyCrossover]: [CarFilterTypeV5.Economy],
  [VehicleKindEnumV5.EconomyEliteCar]: [CarFilterTypeV5.Economy],
  [VehicleKindEnumV5.EconomyHybridSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Hybrid,
    CarFilterTypeV5.Economy,
  ],
  [VehicleKindEnumV5.EconomySpecial]: [CarFilterTypeV5.Economy],
  [VehicleKindEnumV5.EconomySUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Economy,
  ],
  [VehicleKindEnumV5.FullSize2WheelVehicle]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeCar]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeCrossover]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeElectricCar]: [
    CarFilterTypeV5.FullSize,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.FullSizeElite2WheelVehicle]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeEliteCar]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeEliteSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.FullSizeEliteVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.FullSizeHybridCar]: [
    CarFilterTypeV5.Hybrid,
    CarFilterTypeV5.FullSize,
  ],
  [VehicleKindEnumV5.FullSizeOpenAirAllTerrain]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.FullSizePickupTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.FullSizePickupTruckWithExtendedCab]: [
    CarFilterTypeV5.Truck,
  ],
  [VehicleKindEnumV5.FullSizeRecreationalVehicle]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.FullSizeSpecial]: [CarFilterTypeV5.FullSize],
  [VehicleKindEnumV5.FullSizeSportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.FullSizeSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.FullSizeVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.FullSizeWagon]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.Luxury2WheelVehicle]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.LuxuryCrossover]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryElectricSUV]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.LuxuryEliteCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryEliteElectricCar]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.LuxuryEliteSpecial]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryEliteSportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxuryEliteSUV]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.SUV,
  ],
  [VehicleKindEnumV5.LuxurySpecial]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxurySportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.LuxurySUV]: [CarFilterTypeV5.Luxury, CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.MidSizeCar]: [CarFilterTypeV5.MidSize],
  [VehicleKindEnumV5.MidSizeCommercialVanTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.MidSizeConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.MidSizeCrossover]: [CarFilterTypeV5.MidSize],
  [VehicleKindEnumV5.MidSizeElectricCar]: [
    CarFilterTypeV5.Electric,
    CarFilterTypeV5.MidSize,
  ],
  [VehicleKindEnumV5.MidSizeElectricSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.MidSizeEliteCar]: [CarFilterTypeV5.MidSize],
  [VehicleKindEnumV5.MidSizeEliteSportsCar]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.MidSizeEliteConvertible]: [
    CarFilterTypeV5.MidSize,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.MidSizeEliteCrossover]: [CarFilterTypeV5.MidSize],
  [VehicleKindEnumV5.MidSizeEliteElectricCar]: [
    CarFilterTypeV5.Electric,
    CarFilterTypeV5.MidSize,
  ],
  [VehicleKindEnumV5.MidSizeEliteElectricCrossover]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.MidSizeEliteSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.MidSizeHybridCar]: [
    CarFilterTypeV5.Hybrid,
    CarFilterTypeV5.MidSize,
  ],
  [VehicleKindEnumV5.MidSizeLimousine]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.MidSizeOpenAirAllTerrain]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.MidSizePickupTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.MidSizeSpecial]: [CarFilterTypeV5.MidSize],
  [VehicleKindEnumV5.MidSizeSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.MidSizeVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.MiniCar]: [CarFilterTypeV5.Mini],
  [VehicleKindEnumV5.MiniEliteVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.MiniSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.MiniVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.OverSizePickupTruckWithExtendedCab]: [
    CarFilterTypeV5.Truck,
  ],
  [VehicleKindEnumV5.OverSizeVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.Premium2WheelVehicle]: [CarFilterTypeV5.Premium],
  [VehicleKindEnumV5.PremiumCar]: [CarFilterTypeV5.Premium],
  [VehicleKindEnumV5.PremiumConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumCrossover]: [CarFilterTypeV5.Premium],
  [VehicleKindEnumV5.PremiumElectricCar]: [
    CarFilterTypeV5.Electric,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumEliteConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumEliteCrossover]: [CarFilterTypeV5.Premium],
  [VehicleKindEnumV5.PremiumEliteSportsCar]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumEliteSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumEliteVan]: [
    CarFilterTypeV5.Van,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumLimousine]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumPickupTruck]: [
    CarFilterTypeV5.Truck,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumPickupTruckWithExtendedCab]: [
    CarFilterTypeV5.Truck,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumSportsCar]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumVan]: [
    CarFilterTypeV5.Van,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.PremiumWagon]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Van,
    CarFilterTypeV5.Premium,
  ],
  [VehicleKindEnumV5.SpecialCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.SpecialConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.SpecialSportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.SpecialSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.SpecialVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.Standard2WheelVehicle]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardCar]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardCommercialVanTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.StandardConvertible]: [
    CarFilterTypeV5.Luxury,
    CarFilterTypeV5.Convertible,
  ],
  [VehicleKindEnumV5.StandardCoupe]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardCrossover]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardElectricCar]: [
    CarFilterTypeV5.Electric,
    CarFilterTypeV5.Standard,
  ],
  [VehicleKindEnumV5.StandardEliteCar]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardEliteCoupe]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardEliteCrossover]: [CarFilterTypeV5.Standard],
  [VehicleKindEnumV5.StandardEliteElectricSUV]: [
    CarFilterTypeV5.SUV,
    CarFilterTypeV5.Electric,
  ],
  [VehicleKindEnumV5.StandardEliteSportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.StandardEliteSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.StandardEliteVan]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.StandardHybridSUV]: [
    CarFilterTypeV5.Hybrid,
    CarFilterTypeV5.SUV,
  ],
  [VehicleKindEnumV5.StandardHybridCar]: [
    CarFilterTypeV5.Hybrid,
    CarFilterTypeV5.Standard,
  ],
  [VehicleKindEnumV5.StandardOpenAirAllTerrain]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.StandardPickupTruck]: [CarFilterTypeV5.Truck],
  [VehicleKindEnumV5.StandardPickupTruckWithExtendedCab]: [
    CarFilterTypeV5.Truck,
  ],
  [VehicleKindEnumV5.StandardRecreationalVehicle]: [CarFilterTypeV5.Van],
  [VehicleKindEnumV5.StandardSportsCar]: [CarFilterTypeV5.Luxury],
  [VehicleKindEnumV5.StandardSUV]: [CarFilterTypeV5.SUV],
  [VehicleKindEnumV5.StandardVan]: [CarFilterTypeV5.Van],
};
