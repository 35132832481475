import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography, Switch, withStyles } from "@material-ui/core";
import { RouterProps } from "react-router";
import {
  CarsTripCategory,
  ModalScreens,
  POLICY_MODAL,
  SelectedTravelOfferScreen,
  VIEWED_POLICY_MODAL,
} from "redmond";
import {
  Header,
  ActionButton,
  removeStyleSheetByMetaName,
  Icon,
  IconName,
  PolicyDetailsModal,
  PolicyModalButton,
} from "halifax";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";

import { DesktopCarAvailabilityConnectorProps } from "./container";
import {
  CarAvailabilityList,
  AvailabilityFilters,
  AvailabilityFilterTags,
  AvailabilityNoResults,
} from "../../components";
import {
  PickUpLocationAutocomplete,
  DropOffLocationAutocomplete,
  getSelectedOption,
  CalendarPickerButton,
  PickUpTimeSelector,
  DropOffTimeSelector,
} from "../../../search/components/DesktopCarSearchControl/components";
import { colors } from "../../../../utils/colors";
import { RewardsAccountSelection } from "../../../rewards/components";
import { PATH_HOME, PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
import "./styles.scss";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { calcMinimumDropOffTime } from "../../../search/components/DesktopCarSearchControl/components/TimeSelector/util";
import {
  AVAILABLE,
  CARS_CX_V1,
  CARS_OFF_HOUR_PICKUP,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import {
  TravelWalletDetailsBanner,
  TravelWalletDrawer,
} from "../../../travel-wallet/components";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { config } from "../../../../api/config";

export interface IDesktopCarsAvailability
  extends DesktopCarAvailabilityConnectorProps,
    RouterProps {}

export const DesktopCarsAvailability = (props: IDesktopCarsAvailability) => {
  const {
    pickUplocation,
    fromDate,
    untilDate,
    tripCategory,
    availabilityRequestParameters,
    areRequestParametersChanged,
    isCarAvailabilityInEmptyState,
    setTripCategory,
    history,
    resetFilters,
    credit,
  } = props;

  const { logo, policies, sessionInfo } = useContext(ClientContext);

  const {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  } = availabilityRequestParameters;
  const [locationName, setLocationName] = React.useState("");
  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const isPickUpSameAsDropOff =
    tripCategory === CarsTripCategory.SAME_AS_DROP_OFF;

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const isCarsOffHourPickup = useMemo(
    () =>
      getExperimentVariant(expState.experiments, CARS_OFF_HOUR_PICKUP) ===
      AVAILABLE,
    [expState.experiments]
  );

  const isReadyToSearch =
    !!dropOffDate &&
    !!dropOffTime &&
    !!dropOffLocation &&
    !!pickUpDate &&
    !!pickUpTime &&
    !!pickUpLocation &&
    !!driverAge;

  React.useEffect(() => {
    // TODO: a field that represents city name should be returned from the backend
    const [locationName] = pickUplocation?.label
      ? pickUplocation.label.split(",")
      : [];
    setLocationName(locationName);
  }, [pickUplocation]);

  const minTime: string | undefined = useMemo(() => {
    if (pickUpDate && dropOffDate && pickUpTime) {
      return calcMinimumDropOffTime(pickUpDate, dropOffDate, pickUpTime);
    }
    return;
  }, [pickUpDate, dropOffDate, pickUpTime]);

  const [showTravelWalletBanner, setShowTravelWalletBanner] = useState(false);

  useEffect(() => {
    setShowTravelWalletBanner((credit && credit.amount.amount < 0) || false);
  }, [credit]);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.CARS_AVAILABILITY,
        funnel: "cars",
      },
    });
  };

  return (
    <Box className="desktop-car-availability-root">
      <Box className="desktop-car-availability-container">
        <Header
          className="rewards-components-section"
          left={
            <Box className="rewards-account-section-left-content">
              <Box className="logo" onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>
              <Box className="rewards-account-section-travel-details">
                <Typography variant="body1" tabIndex={0}>
                  {constants.VIEWING_CARS_TEXT(locationName || "...")}
                </Typography>
                {fromDate && untilDate && (
                  <Typography variant="body2">
                    {constants.DATES_TEXT(fromDate, untilDate)}
                  </Typography>
                )}
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-car-availability-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b", {
                  "hide-balance-border": isTravelWalletCreditsExperiment,
                })}
                popoverClassName="b2b"
              />
              {isTravelWalletCreditsExperiment && <TravelWalletDrawer />}
            </Box>
          }
        ></Header>

        {showTravelWalletBanner && (
          <TravelWalletDetailsBanner
            variant="full-width"
            showButton
            onDismiss={() => setShowTravelWalletBanner(false)}
            screen={SelectedTravelOfferScreen.CARS_SHOP}
            bannerTextType="availability"
          />
        )}

        <Box
          className={clsx(
            "car-availability-search-and-filters-section",
            config.TENANT,
            {
              "has-offer": showTravelWalletBanner,
            }
          )}
        >
          <Box className={clsx("search-and-filters-row", "one")}>
            <PickUpLocationAutocomplete
              className={clsx("pick-up-auto-complete", "search-row-field")}
              label={constants.PICK_UP_LOCATION_AUTOCOMPLETE_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              popperClassName="cars-autocomplete-popper"
            />
            <DropOffLocationAutocomplete
              className={clsx("drop-off-auto-complete", "search-row-field", {
                hidden: isPickUpSameAsDropOff,
              })}
              label={constants.DROP_OFF_LOCATION_AUTOCOMPLETE_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              popperClassName="cars-autocomplete-popper"
            />
            <Box className={clsx("date-inputs-wrapper", "search-row-field")}>
              <CalendarPickerButton className="car-availability-date-inputs" />
            </Box>
            <Box className={clsx("time-pickers-wrapper", "search-row-field")}>
              <Box className="car-availability-time-pickers">
                <PickUpTimeSelector
                  increment={isCarsOffHourPickup ? 60 : 30}
                  className={clsx("car-availability-time-picker", "pick-up")}
                />
                <DropOffTimeSelector
                  className={clsx("car-availability-time-picker", "drop-off")}
                  minTime={minTime}
                  increment={isCarsOffHourPickup ? 60 : 30}
                />
              </Box>
            </Box>
            {areRequestParametersChanged && (
              <ActionButton
                className="car-availability-search-button"
                onClick={() => {
                  history.push(
                    `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                      dropOffDate!,
                      dropOffTime!,
                      dropOffLocation!,
                      pickUpDate!,
                      pickUpTime!,
                      pickUpLocation!,
                      driverAge!
                    )}`
                  );
                  resetFilters();
                }}
                disabled={!isReadyToSearch}
                message={constants.SEARCH_TEXT}
                defaultStyle="h4r-primary"
              />
            )}
          </Box>
          <Box
            className={clsx("search-and-filters-row", "two", {
              "cars-cx-v1": isCarsCXV1Experiment,
            })}
          >
            {!isCarsCXV1Experiment && (
              <DropOffOptionSwitch
                tripCategory={tripCategory}
                setTripCategory={setTripCategory}
              />
            )}
            <AvailabilityFilters
              modalOpen={filterModalOpen}
              setModalOpen={setFilterModalOpen}
            />
          </Box>
          {!isCarsCXV1Experiment && (
            <Box className="filter-tags-section">
              <AvailabilityFilterTags />
            </Box>
          )}
        </Box>
        {isPolicyDescriptorsEnabled && (
          <>
            <Box className="policy-modal-button-container">
              <Box>
                <PolicyModalButton
                  underline={false}
                  onClick={onShowPolicyDetailsModal}
                />
              </Box>
            </Box>
            <PolicyDetailsModal
              policies={policies}
              sessionInfo={sessionInfo}
              isOpen={isPolicyModalOpen}
              setIsOpen={setIsPolicyModalOpen}
              productType="car"
            />
          </>
        )}
        {isCarAvailabilityInEmptyState ? (
          <Box className="car-availability-no-results-wrapper">
            <AvailabilityNoResults />
          </Box>
        ) : (
          <Box className="car-availability-list-wrapper">
            <CarAvailabilityList />
          </Box>
        )}
      </Box>
    </Box>
  );
};

// TODO: refactor DropOffOptionSwitch so that it's stored in its own .tsx file;
// it's copied from /LocationSearch/component.tsx, with changes to accommodate a smaller switchBase
const StyledSwitch = withStyles({
  switchBase: {
    padding: "6px",
    "&$checked + $track": {
      backgroundColor: colors["blue-9"],
    },
  },
  track: {
    borderRadius: "16px",
    opacity: 1,
    backgroundColor: colors["grey-8"],
  },
  thumb: {},
})(Switch);

const DropOffOptionSwitch = ({
  tripCategory,
  setTripCategory,
}: {
  tripCategory: CarsTripCategory;
  setTripCategory: (tripCategory: CarsTripCategory) => void;
}) => {
  const handleTripCategoryChange = () => {
    if (tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF) {
      setTripCategory(CarsTripCategory.SAME_AS_DROP_OFF);
    } else {
      setTripCategory(CarsTripCategory.DIFFERENT_DROP_OFF);
    }
  };

  React.useEffect(() => {
    removeStyleSheetByMetaName("PrivateSwitchBase");
  }, []);

  return (
    <Box className="drop-off-option-switch-root">
      <Box
        onClick={handleTripCategoryChange}
        className="drop-off-option-switch-container"
      >
        <label className="drop-off-option-label" htmlFor="drop-off-checkbox">
          {constants.TRIP_CATEGORY_LABEL}
        </label>
        <StyledSwitch
          id="drop-off-checkbox"
          checked={tripCategory === CarsTripCategory.SAME_AS_DROP_OFF}
          focusVisibleClassName={"drop-off-option-switch-focus-visible"}
          disableRipple
          className={"cars-drop-off-option-switch"}
          classes={{
            thumb: "drop-off-option-switch-thumb",
            track: "drop-off-option-switch-track",
            switchBase: "drop-off-option-switch-base",
          }}
        />
      </Box>
    </Box>
  );
};
